html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 100vw; */
  /* min-height: 100vh; */
  height: 100%;
  background: rgb(29,28,46);
  background: -moz-radial-gradient(circle, rgba(29,28,46,1) 0%, rgba(10,10,10,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(29,28,46,1) 0%, rgba(10,10,10,1) 100%);
  background: radial-gradient(circle, rgba(29,28,46,1) 0%, rgba(10,10,10,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d1c2e",endColorstr="#0a0a0a",GradientType=1);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
  line-height: 2.5rem;
  text-decoration: underline;
  text-decoration-color: rgb(164, 14, 76);
  text-decoration-thickness: 10%;
}

p {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
  text-decoration-color: inherit;
  transition: transform 0.2s ease-out;
}

a:hover, a:active {
  transform: scale(1.1);
}

.Underline {
  text-decoration: underline;
  text-decoration-color: rgb(164, 14, 76);
  text-decoration-thickness: 10%;
}

.HeaderRight, .AboutMeRight, .Project {
  transition: border-left-color 0.5s ease;
}

.HeaderRight:hover, .HeaderRight:active, .AboutMeRight:hover, .AboutMeRight:active, .Project:active, .Project:hover {
  border-left-color: white;
}

/* containers */

@media only screen and (max-width: 1000px) {
  .App {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    max-width: 100vw;
  }

  .HeaderLeft {
    margin-top: 10rem;
    align-self: flex-start;
    margin-left: 1rem;
  }

  .HeaderRight {
    justify-self: start;
    display: flex;
    flex-flow: column;
    margin-left: 2rem;
  }

  .AboutMeRight {
    margin-top: 10rem;
    line-height: 2.3rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    max-width: 100vw;
    margin-bottom: 5rem;
  }

  .ContentRight {
    margin-top: 5rem;
    border-top: 1px solid rgb(110, 110, 147);
    border-bottom: 1px solid rgb(110, 110, 147);
    padding-top: 2rem;
    padding-bottom: 5rem;
    max-width: 100vw;
  }

  .ContentRight, .AboutMeRight {
    box-sizing: border-box;
    justify-self: start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .Buttons {
    display: flex;
    flex-flow: row wrap;
  }

  .Project {
    box-sizing: border-box;
    max-width: 100vw;
    flex: 1 1 20vh;
    margin-top: 2rem;
    margin-bottom: 6rem;
    margin-left: -2rem;
    padding-left: 2rem;
    line-height: 1.5rem;
  }

  .FooterRight {
    grid-area: FooterRight;
    justify-self: start;
    align-self: end;
    padding: 2rem;
  }
}

@media only screen and (min-width: 1000px) {
  .App {
    display: grid;
    grid-template-columns: 10vw auto;
    grid-template-rows: 30rem 17rem auto 5rem;
    grid-template-areas: 
      "HeaderLeft HeaderRight"
      "AboutMeLeft AboutMeRight"
      "ContentLeft ContentRight"
      "FooterLeft FooterRight"
    ;
    justify-items: center;
    align-items: center;
  }

  .HeaderRight {
    grid-area: HeaderRight;
    justify-self: start;
    display: flex;
    flex-flow: column;
    border-left: 1px solid rgb(110, 110, 147);
    padding-left: 2rem;
  }

  .AboutMeLeft {
    grid-area: AboutMeLeft;
  }

  .AboutMeRight {
    line-height: 2.3rem;
    border-left: 1px solid rgb(110, 110, 147);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    max-width: 35vw;
  }

  .ContentLeft {
    grid-area: ContentLeft;
  }

  .ContentRight, .AboutMeRight {
    justify-self: start;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 35vw;
  }

  .ContentRight {
    margin-top: 20rem;
    border-top: 1px solid rgb(110, 110, 147);
    border-bottom: 1px solid rgb(110, 110, 147);
    padding-top: 2rem;
    padding-bottom: 10rem;
  }

  .Project {
    box-sizing: border-box;
    /* background-color: rgb(50, 50, 66); */
    border-left: 1px solid rgb(110, 110, 147);
    /* border-radius: 0.5rem; */
    /* padding: 2rem; */
    flex: 1 1 20vh;
    margin-top: 2rem;
    margin-bottom: 6rem;
    margin-left: -2rem;
    padding-left: 2rem;
    line-height: 1.5rem;
  }

  .FooterLeft {
    grid-area: FooterLeft;
  }

  .FooterRight {
    grid-area: FooterRight;
    justify-self: start;
    align-self: end;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }
}

.Technologies {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1.5rem 0;
}

.Description {
  font-size: 1.15rem;
}

.Video {
  margin: 2rem 0;
}

.Buttons {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2rem;
}

.ButtonSection {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
}

.ButtonSection:last-of-type {
  padding-top: 1.5rem;
  padding-left: 0.5rem;
}

/* buttons */

.DemoButton, .SourceButton, .ContactButton {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  margin-right: 1.2rem;
}

/* .SocialButton {
  margin-right: 1.2rem;
  color: rgb(110, 110, 147);
  background-color: red;
  vertical-align: middle;
} */

.DemoButton {
  background-color: rgb(164, 14, 76);
  /* background-color: rgb(244, 201, 93); */
  font-weight: 700;
}

.ContactButton {
  background-color: rgb(164, 14, 76);
  font-weight: 700;
}

.SourceButton {
  color: rgb(244, 201, 93);
  border: 1px solid rgb(70, 60, 33);
}

/* Spans */

.EnglishName {
  font-size: 2.5rem;
  font-weight: 700;
}

.ChineseName {
  font-size: 2rem;
  font-weight: 100;
}

.MyTitle {
  color: rgb(177, 255, 170);
  margin-bottom: 1rem;
  letter-spacing: 0.4rem;
}

.AboutMe {
  font-size: 1.5rem;
}

.Technology {
  box-sizing: border-box;
  color: rgb(177, 255, 170);
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
}

.Technology:nth-last-of-type(1):after {
  border: none;
}

.Technology:after {
  content: '';
  border: 1px solid rgb(78, 89, 77);
  margin: 1rem;
  padding: 0.1rem 0;
}

/* images */

.Logo {
  max-width: 7rem;
}

svg {
  margin-right: 1.2rem;
  display: block;
}

/* Animation */
/* .HeaderLeft {
  animation: down 0.8s ease-in-out;
}

.HeaderRight {
  animation: up 0.8s ease-in-out;
}

.AboutMeLeft, .AboutMeRight {
  animation: appear 1.5s backwards 1s;
}

.ContentLeft, .ContentRight {
  animation: appear 2s backwards 2.5s;
}

.FooterLeft, .FooterRight {
  animation: appear 1s backwards 3.5s;
} */

@keyframes appear {
  0% { opacity: 0%;}
  100% { opacity: 100%; }
}

@keyframes left {
  0% { transform: translateX(-1rem);}
  100% { transform: translateX(0); }
}

@keyframes right {
  0% { transform: translateX(1rem);}
  100% { transform: translateX(0); }
}

@keyframes down {
  0% { transform: translateY(-1rem); opacity: 0%; }
  100% { transform: translateY(0); opacity: 100%; }
}

@keyframes up {
  0% { transform: translateY(1rem); opacity: 0%; }
  100% { transform: translateY(0); opacity: 100%; }
}

@keyframes test {
  0% { border-left: none; }
  100% { border-left: 1px solid white; }
}